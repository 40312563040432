import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import RestaurantDataService from "./services/restaurant";
import RestaurantsList from "./restaurants-list";
import "./Modal.css";

Modal.setAppElement("#root");

function CardItem(props) {
  const [restaurants, setRestaurants] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const buttonAddress = {"https://www.google.com/maps/place/" `${restaurant.address}`}

  return (
    <>
      <div className="item__container">
        <li className="cards__item">
          <Link className="cards__item__link" to={props.path}>
            <figure
              className="cards__item__pic-cuisine"
              data-category={props.cuisine}
            >
              <figure
                className="cards__item__pic-location"
                data-location={props.location}
              >
                <figure
                  className="cards__item__pic-vibes"
                  data-vibes={props.vibes}
                >
                  <figure
                    className="cards__item__pic-restaurantName"
                    data-restaurantName={props.restaurantName}
                  >
                    <figure
                      className="cards__item__pic-moreDetails"
                      data-moreDetails={props.moreDetails}
                    >
                      {/* <figure
                      className="cards__item__pic-address"
                      data-address={props.address}
                    >
                      <figure
                        className="cards__item__pic-phone"
                        data-phone={props.phone}
                      > */}
                      <img
                        className="cards__item__img"
                        alt="Travel Image"
                        src={props.src}
                        onClick={() => setModalIsOpen(true)}
                      />
                    </figure>
                  </figure>
                </figure>
              </figure>
            </figure>
            {/* <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
          </div> */}
          </Link>
        </li>
      </div>
      <div>
        <Modal
        
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
        >
          <div className="modal-all">
            <div className="modal-text">
              <div className="modal-name">{props.restaurantName}</div>
              <br />
              <div className="modal-text-inside"> 🥗 {props.cuisine}</div>
              <div className="modal-text-inside"> 👯‍♀️ {props.vibes}</div>
              <div className="modal-text-inside"> 🏙 {props.location}</div>
              <div className="modal-text-inside"> 📲 {props.phone}</div>
              <div className="modal-text-inside"> 🕦 {props.openingHours}</div>
              <div className="modal-text-inside"> 🪧 {props.address} </div>

              {/* <button type="button">Show me on the map!</button> */}
              <a
                className="modal-anchor"
                target="_blank"
                href={"https://www.google.com/maps/place/" + `${props.address}`}
              >
                📍 View On Map
              </a>
              <br />
            </div>
          </div>
          <div class="row">
            <div className="modal-image">
              <img
                className="column"
                // className='modal-right'
                alt="Restaurant Image"
                src={props.src}
              />
              <img
                className="column"
                // className='modal-right'
                alt="Restaurant Image2"
                src={props.src2}
              />
              <img
                className="column"
                // className='modal-right'
                alt="Restaurant Image3"
                src={props.src3}
              />
              <img
                className="column"
                // className='modal-right'
                alt="Restaurant Image4"
                src={props.src4}
              />
              <img
                className="column"
                // className='modal-right'
                alt="Restaurant Image5"
                src={props.src5}
              />
              <img
                className="column"
                // className='modal-right'
                alt="Restaurant Image6"
                src={props.src6}
              />
              <img
                className="column"
                // className='modal-right'
                alt="Restaurant Image6"
                src={props.src7}
              />
              <img
                className="column"
                // className='modal-right'
                alt="Restaurant Image6"
                src={props.src8}
              />
              <img
                className="column"
                // className='modal-right'
                alt="Restaurant Image6"
                src={props.src9}
              />
            </div>
          </div>
          <div>
            <button onClick={() => setModalIsOpen(false)}>Close</button>
          </div>
        </Modal>
      </div>
    </>
  );
}
export default CardItem;
