import React from 'react';
import './App.css';
import Cards from './Cards';
import HeroSection from './HeroSection';
import Footer from './Footer';
import RestaurantsList from './restaurants-list';

function Home() {
  return (
    <>
      {/* <HeroSection /> */}
      <RestaurantsList />
      <Footer />
    </>
  );
}

export default Home;
