// import React from "react";
// import { Switch, Route, Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";

// import Restaurant from "./components/restaurants";
// import RestaurantsList from "./components/restaurants-list";

// function App() {

//   return (
//     <div>
//       <nav className="navbar navbar-expand navbar-dark bg-dark">
//         <a href="/restaurants" className="navbar-brand">
//           Restaurant Reviews
//         </a>
//         <div className="navbar-nav mr-auto">
//           <li className="nav-item">
//             <Link to={"/restaurants"} className="nav-link">
//               Restaurants
//             </Link>
//           </li>
//         </div>
//       </nav>

//       <div className="container mt-3">
//         <Switch>
//           <Route exact path={["/", "/restaurants"]} component={RestaurantsList} />

//           <Route 
//             path="/restaurants/:id"
//             render={(props) => (
//               <Restaurant {...props}/>
//             )}
//           />
//         </Switch>
//       </div>
//     </div>
//   );
// }

// export default App;




import React from 'react';
import Modal from 'react-modal'
import Navbar from './Navbar';
import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Services from './components/pages/Services';
// import Products from './components/pages/Products';
// import SignUp from './components/pages/SignUp';


function App() {
  return (

    <>
      <Router>
        {/* <Navbar /> */}
        <Switch>
          <Route path='/' exact component={Home} />
          {/* <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/sign-up' component={SignUp} /> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
