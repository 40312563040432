import React, { useState, useEffect } from "react";
import RestaurantDataService from "./services/restaurant";
import { Link } from "react-router-dom";
import { Button } from "./Button";
import "./Cards.css";
import CardItem from "./CardItem";
import "./HeroSection.css";
import "./Button.css";
import "./Buttons.css";
// import './HeroSection.css';

const RestaurantsList = (props) => {
  const [restaurants, setRestaurants] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchCuisine, setSearchCuisine] = useState("");
  const [cuisines, setCuisines] = useState(["All Cuisines"]);

  const [searchVibes, setSearchVibes] = useState("");

  // const [openingHours, setOpeningHours] = useState([]);
  // const [vibes, setVibes] = useState([]);

  useEffect(() => {
    retrieveRestaurants();
    retrieveCuisines();
  }, []);

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const onChangeSearchAddress = (e) => {
    const searchAddress = e.target.value;
    setSearchAddress(searchAddress);
  };

  const onChangeSearchCuisine = (e) => {
    const searchCuisine = e.target.value;
    setSearchCuisine(searchCuisine);
  };

  const onChangeSearchVibes = (e) => {
    const searchVibes = e.target.value;
    setSearchVibes(searchVibes);
  };

  const retrieveRestaurants = () => {
    RestaurantDataService.getAll()
      .then((response) => {
        console.log(response.data);
        setRestaurants(response.data.restaurants);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveCuisines = () => {
    RestaurantDataService.getCuisines()
      .then((response) => {
        console.log(response.data);
        setCuisines(["All Cuisines"].concat(response.data));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const refreshList = () => {
    retrieveRestaurants();
  };

  const find = (query, by) => {
    RestaurantDataService.find(query, by)
      .then((response) => {
        console.log(response.data);
        setRestaurants(response.data.restaurants);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findByName = () => {
    find(searchName, "name");
  };

  const findByVibes = () => {
    find(searchVibes, "vibes");
  };

  const findByAddress = () => {
    find(searchAddress, "address");
  };

  const findByCuisine = () => {
    if (searchCuisine === "All Cuisines") {
      refreshList();
    } else {
      find(searchCuisine, "cuisine");
    }
  };

  return (
    <div>
      <div>
        <div className="hero-container">
          <h1>DISCOVER. BOOK. ENJOY.</h1>
          <p>How would you like to vibe today 👀</p>

          <div>
            <div className="search-div">
              <div className="search-div-inside">
                <input
                  className="search-bar"
                  type="text"
                  // className="form-control"
                  placeholder="Search by area or cuisine"
                  value={searchName}
                  onChange={onChangeSearchName}
                />

                <button
                  type="submit"
                  className="btn--search"
                  // buttonStyle="btn--go"
                  // buttonSize="btn--large"
                  // type="button"
                  onClick={findByName}
                >
                  Let's Go!
                </button>
              </div>
            </div>

            <div className="buttons-div">
              <button
                className="btn--fancy"
                // buttonStyle="btn--go"
                // buttonSize="btn--large"
                // type="button"
                onClick={() => find("Fancy")}
              >
                🤵‍♂️ Fancy
              </button>
              <button
                className="btn--quirky"
                // buttonStyle="btn--go"
                // buttonSize="btn--large"
                // type="button"
                onClick={() => find("Quirky")}
              >
                👩‍🎤 Quirky
              </button>
              <button
                className="btn--casual"
                // buttonStyle="btn--go"
                // buttonSize="btn--large"
                // type="button"
                onClick={() => find("Casual")}
              >
                💁‍♂️ Casual
              </button>
              <button
                className="btn--budget"
                // buttonStyle="btn--go"
                // buttonSize="btn--large"
                // type="button"
                onClick={() => find("Budget")}
              >
                🦹‍♂️ Budget
              </button>
            </div>
            <div className="buttons-div-lower">
              <button
                className="btn--restaurants"
                // buttonStyle="btn--go"
                // buttonSize="btn--large"
                // type="button"
                onClick={() => find("restaurant")}
              >
                🍜 Restaurants
              </button>
              <button
                className="btn--bars"
                // buttonStyle="btn--go"
                // buttonSize="btn--large"
                // type="button"
                onClick={() => find("bar" || "pub")}
              >
                🍹 Bars & Pubs
              </button>
            </div>
            <div className="buttons-div-lower">
              <button
                className="btn--bars"
                // buttonStyle="btn--go"
                // buttonSize="btn--large"
                // type="button"
                onClick={refreshList}
              >
                🙆‍♀️ See all
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="restaurants__item">
        {/* {restaurants.map((restaurant) => { */}
        {restaurants.map((restaurant) => {
          return (
            <div>
              {/* <div className="cards"> */}
              {/* <div className="cards__container"> */}
              {/* <div className="cards__wrapper"> */}
              {/* <ul className="cards__items"> */}
              {/* <div className="cards__item__link"> */}
              <CardItem
                // class="cards__item"
                src={restaurant.imageURL}
                src2={restaurant.imageURL2}
                src3={restaurant.imageURL3}
                src4={restaurant.imageURL4}
                src5={restaurant.imageURL5}
                src6={restaurant.imageURL6}
                src7={restaurant.imageURL7}
                src8={restaurant.imageURL8}
                src9={restaurant.imageURL9}
                restaurantName={restaurant.name}
                openingHours={restaurant.openingHours}
                vibes={restaurant.vibes}
                cuisine={restaurant.cuisine}
                location={restaurant.area}
                address={restaurant.address}
                phone={restaurant.phone}
              />
              {/* </div> */}
              {/* </ul> */}
              {/* </div> */}
              {/* </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RestaurantsList;

// <p>
// {restaurant.name}
// {restaurant.openingHours}
// {restaurant.vibes}
// {/* {restaurant.imageURL} */}

// <strong>Cuisine: </strong>
// {restaurant.cuisine}
// <br />
// <strong>Address: </strong>
// {address}
// </p>
// <div className="row">
// <a
//   target="_blank"
//   href={"https://www.google.com/maps/place/" + address}
//   className="btn btn-primary col-lg-5 mx-1 mb-1"
// >
//   View Map
// </a>
// </div>

// <div className="cards">
//   <h1>Check out these EPIC Destinations!</h1>
//   <div className="cards__container">
//     <div className="cards__wrapper">
//       <ul className="cards__items">
//         <CardItem
//           src="images/img-1-london-bridge.jpg"
//           // text='A cool urban rooftop bar in the heart of London Bridge perched on top of a 70s style brutalist office block'
//           cousine={restaurant.cuisine}
//           location={address}
//           vibes="Hipster"
//           restaurantName="Ristorante Frescobaldi"
//           // path='/services'
//           onClick={() => setModalIsOpen(true)}
//         />
//       </ul>
//     </div>
//   </div>
// </div>

//NEEEEEEEEEEEEEWWWWWWW
// import React, { useState, useEffect } from "react";
// import RestaurantDataService from "./services/restaurant";
// import { Link } from "react-router-dom";
// import { Button } from "./Button";
// import "./Cards.css";
// import CardItem from "./CardItem";

// // import './HeroSection.css';

// const RestaurantsList = (props) => {
//   const [restaurants, setRestaurants] = useState([]);
//   const [searchName, setSearchName] = useState("");
//   const [searchAddress, setSearchAddress] = useState("");
//   const [searchCuisine, setSearchCuisine] = useState("");
//   const [cuisines, setCuisines] = useState(["All Cuisines"]);

//   useEffect(() => {
//     retrieveRestaurants();
//     retrieveCuisines();
//   }, []);

//   const onChangeSearchName = (e) => {
//     const searchName = e.target.value;
//     setSearchName(searchName);
//   };

//   const onChangeSearchAddress = (e) => {
//     const searchAddress = e.target.value;
//     setSearchAddress(searchAddress);
//   };

//   const onChangeSearchCuisine = (e) => {
//     const searchCuisine = e.target.value;
//     setSearchCuisine(searchCuisine);
//   };

//   const retrieveRestaurants = () => {
//     RestaurantDataService.getAll()
//       .then((response) => {
//         console.log(response.data);
//         setRestaurants(response.data.restaurants);
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   };

//   const retrieveCuisines = () => {
//     RestaurantDataService.getCuisines()
//       .then((response) => {
//         console.log(response.data);
//         setCuisines(["All Cuisines"].concat(response.data));
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   };

//   const refreshList = () => {
//     retrieveRestaurants();
//   };

//   const find = (query, by) => {
//     RestaurantDataService.find(query, by)
//       .then((response) => {
//         console.log(response.data);
//         setRestaurants(response.data.restaurants);
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   };

//   const findByName = () => {
//     find(searchName, "name");
//   };

//   const findByAddress = () => {
//     find(searchAddress, "address");
//   };

//   const findByCuisine = () => {
//     if (searchCuisine === "All Cuisines") {
//       refreshList();
//     } else {
//       find(searchCuisine, "cuisine");
//     }
//   };

//   return (
//     <div>

//       <div className="row pb-1">
//         <div className="input-group col-lg-4">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Search by name"
//             value={searchName}
//             onChange={onChangeSearchName}
//           />
//           <div className="input-group-append">
//             <button
//               className="btn btn-outline-secondary"
//               type="button"
//               onClick={findByName}
//             >
//               Search
//             </button>
//           </div>
//         </div>
//         <div className="input-group col-lg-4">
//           <input
//             type="text"
//             className="form-control"
//             placeholder="Search by address"
//             value={searchAddress}
//             onChange={onChangeSearchAddress}
//           />
//           <div className="input-group-append">
//             <button
//               className="btn btn-outline-secondary"
//               type="button"
//               onClick={findByAddress}
//             >
//               Search
//             </button>
//           </div>
//         </div>
//         <div className="input-group col-lg-4">
//           <select onChange={onChangeSearchCuisine}>
//             {cuisines.map((cuisine) => {
//               return <option value={cuisine}> {cuisine.substr(0, 20)} </option>;
//             })}
//           </select>
//           <div className="input-group-append">
//             <button
//               className="btn btn-outline-secondary"
//               type="button"
//               onClick={findByCuisine}
//             >
//               Search
//             </button>
//           </div>
//         </div>
//       </div>

//         {restaurants.map((restaurant) => {
//           const address = `${restaurant.address}`;
//           // const address = `${restaurant.address.building} ${restaurant.address.street}, ${restaurant.address.zipcode}`;
//           return (
//             <div className="col-lg-4 pb-1">
//               <div className="card">
//                 <div className="card-body">
//                   <h5 className="card-title">{restaurant.name}</h5>
//                   <p className="card-text">
//                     <strong>Cuisine: </strong>{restaurant.cuisine}<br/>
//                     <strong>Address: </strong>{address}
//                   </p>
//                   <div className="row">
//                   <a target="_blank" href={"https://www.google.com/maps/place/" + address} className="btn btn-primary col-lg-5 mx-1 mb-1">View Map</a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           );
//         })}
//     </div>
//   );
// };

// export default RestaurantsList;

//     // <div className="cards">
//     //   <h1>Check out these EPIC Destinations!</h1>
//     //   <div className="cards__container">
//     //     <div className="cards__wrapper">
//     //       <ul className="cards__items">
//     //         <CardItem
//     //           src="images/img-1-london-bridge.jpg"
//     //           // text='A cool urban rooftop bar in the heart of London Bridge perched on top of a 70s style brutalist office block'
//     //           cousine={restaurant.cuisine}
//     //           location={address}
//     //           vibes="Hipster"
//     //           restaurantName="Ristorante Frescobaldi"
//     //           // path='/services'
//     //           onClick={() => setModalIsOpen(true)}
//     //         />
//     //       </ul>
//     //     </div>
//     //   </div>
//     // </div>
